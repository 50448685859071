import React from "react";

const Copyright = () => {
  return (
    <div className="bg-gray-700 text-xs text-white text-center py-4 lg:text-sm">
      <p>&copy; 2024 Absolute Shines Cleaning. All rights reserved.</p>
      <p>
        Crafted by{" "}
        <a href="https://tekkzen.com" className="text-blue-400 hover:underline">
          Tekkzen
        </a>
      </p>
    </div>
  );
};

export default Copyright;
