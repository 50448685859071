import Navbar from "../../Components/Navbar/Navbar";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";

const About = () => {
  return (
    <div>
      <Navbar />
      <AboutUs />
      <Footer />
      <Copyright />
    </div>
  );
};

export default About;
