import React from "react";
import facebook from "../../Assets/General/facebook.png";
import instagram from "../../Assets/General/instagram.png";
import tiktok from "../../Assets/General/tiktok.png";
import phone from "../../Assets/General/phone.png";
import email from "../../Assets/General/email.png";
import home from "../../Assets/General/home.png";

const Footer = () => {
  return (
    <div className="py-6 px-4 flex flex-col items-center justify-between bg-gray-800 text-white lg:py-10 lg:px-10 lg:flex-row lg:items-start">
      {/* Company Information */}
      <div className="w-full flex flex-col mb-6 lg:w-2/3 lg:pe-24">
        <div className="text-xl text-center mb-4 font-bold lg:text-3xl lg:text-start">
          Absolute Shines Cleaning
        </div>
        <p className="mb-4 text-center text-sm lg:text-base lg:text-start">
          At Absolute Shines Cleaning, we ensure your spaces are spotless and
          inviting. We provide solutions that make your business shine.
        </p>
        <div className="flex items-start justify-center lg:items-center lg:justify-start">
          <img
            className="h-8 w-8 mr-3 cursor-pointer"
            src={facebook}
            alt="Facebook"
          />
          <img
            className="h-8 w-8 mr-3 cursor-pointer"
            src={instagram}
            alt="Instagram"
          />
          <img
            className="h-8 w-8 mr-3 cursor-pointer"
            src={tiktok}
            alt="TikTok"
          />
        </div>
      </div>

      {/* Contact Information */}
      <div className="w-full flex flex-col lg:w-1/3">
        <div className="text-center mb-4 font-bold text-xl lg:text-2xl lg:text-left">
          Contact Information
        </div>
        <div className="flex flex-col justify-center items-center text-sm font-light lg:items-start lg:text-base lg:font-normal lg:space-y-4">
          <div className="mb-2 flex items-center">
            <img className="h-6 w-6 mr-4" src={home} alt="Home" />
            No. 10, Flower Road, Colombo
          </div>
          <div className="mb-2 flex items-center">
            <img className="h-6 w-6 mr-4" src={email} alt="Email" />
            companyemail@company.com
          </div>
          <div className="mb-2 flex items-center">
            <img className="h-6 w-6 mr-4" src={phone} alt="Phone" />
            0112-555-55
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
