import { FC } from "react";

interface CardCleaningServiceProps {
  heading: string;
  description: string;
  image: any;
}

const CardCleaningService: FC<CardCleaningServiceProps> = ({
  heading,
  description,
  image,
}) => {
  return (
    <div className="w-11/12 rounded-md bg-white transition-transform transform shadow-lg duration-300 ease-in-out cursor-pointer hover:-translate-y-[3px] hover:shadow-xl hover:bg-white lg:w-[380px] lg:h-[500px]">
      <div className="lg:w-[380px] lg:h-[280px] rounded-md object-fill">
        <img
          src={image}
          alt="Cleaning Service"
          className="w-full h-full rounded-t-md"
        />
      </div>
      <div className="py-3 px-6">
        <div className="text-base font-bold text-center lg:text-xl">
          {heading}
        </div>
        <div className="mt-2 text-sm font-light lg:text-base">
          {description}
        </div>
      </div>
    </div>
  );
};

export default CardCleaningService;
