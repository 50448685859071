import React from "react";
import backgroundWave from "../../Assets/Home/bg-wave.png";
import FormBookService from "../FormBookService/FormBookService";
import phone from "../../Assets/General/phone.png";
import email from "../../Assets/General/email.png";
import home from "../../Assets/General/home.png";
const WriteToUs = () => {
  return (
    <div className="w-full relative pb-12 bg-gray-100">
      <div className="w-full py-8 flex flex-col lg:flex-row lg:py-16">
        {/* Left Side Column */}
        <div className="w-full pb-4 flex flex-col order-1 lg:w-1/2 lg:order-1">
          <div className="px-8 text-justify">
            <div className="text-xl font-bold lg:text-4xl">
              Please fee free tp write to us!
            </div>
            <div className="mt-2 text-sm font-medium lg:text-xl lg:mt-4  ">
              We excel in providing top-tier commercial cleaning services as
              industry leaders
            </div>
            <div className="mt-2 text-sm font-light leading-relaxed lg:mt-8 lg:text-base">
              At Absolute Shines Cleaning, we pride ourselves on offering
              tailored cleaning solutions that suit your specific needs. From
              routine maintenance to detailed deep cleaning, our team delivers
              exceptional results every time. Simply provide your details below,
              and let us transform your space into a spotless, welcoming
              environment. Your satisfaction is our priority
              <br /> <br />
              Experience the shine today!
            </div>
            {/* Contact Card */}
            <div className="bg-gray-800 mt-4 text-white mx-auto rounded-md p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 ">
                {/* First Column */}
                <div className=" flex items-center space-x-3  rounded-md">
                  <div className="flex flex-col self-start flex-shrink-0">
                    <img className="w-5 h-4" src={home} alt="home icon" />
                  </div>
                  <div className="text-left text-sm lg:text-base ">
                    <div>Address</div>
                    <div className="">No. 10, Flower Road, Colombo</div>
                  </div>
                </div>

                {/* Second Column */}
                <div className=" flex items-center space-x-3  rounded-md">
                  <div className="flex flex-col self-start flex-shrink-0">
                    <img className="w-5 h-4" src={phone} alt="home icon" />
                  </div>
                  <div className="flex flex-col self-start text-sm lg:text-base ">
                    <div>Call Us</div>
                    <div>0112-555-555</div>
                    <div>0112-555-555</div>
                  </div>
                </div>
                {/* Third Column */}
                <div className=" flex items-center space-x-3  rounded-md">
                  <div className="flex  self-start flex-shrink-0">
                    <img className="w-5 h-4" src={email} alt="home icon" />
                  </div>
                  <div className="text-left text-sm lg:text-base ">
                    <div>Email</div>
                    <div className="text-sm  break-words">
                      sales@absoluteshinescleaning.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side Column */}
        <div className="w-full order-2 lg:w-1/2 lg:order-2">
          <FormBookService />
        </div>
      </div>
      <div className="w-full absolute bottom-0">
        <img src={backgroundWave} alt="wave" className="w-full" />
      </div>
    </div>
  );
};

export default WriteToUs;
