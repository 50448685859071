import ReasonToChooseData from "../../Data/ReasonToChoose.json";
import styles from "./ReasonToChoose.module.css";

const ReasonsToChoose = () => {
  return (
    <div className={`w-full ${styles.backgroundImage} text-white lg:h-[650px] lg:text-black`}>
      <div className="h-full flex flex-col items-end justify-center text-start bg-slate-800/75 lg:py-12 lg:bg-transparent">
        <div className="w-full px-8 pt-6 pb-4 lg:w-1/2 lg:pe-8 ">
          <div className="text-xl font-bold lg:text-4xl">Reasons to Choose Us</div>
          <div className="mt-4 text-sm font-light lg:mt-8 lg:text-base">
            When it comes to selecting a cleaning partner for your commercial
            needs, the choice is clear. Rowles and James Cleaning Services
            stands out as the premier option for a multitude of compelling
            reasons:
          </div>
          <div className="mt-8">
            {ReasonToChooseData.map((reason, index) => (
              <div key={index} className="mb-4 text-sm">
                <div className="font-bold">{reason.heading}</div>
                <div className="mt-2">{reason.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReasonsToChoose;
