import serviceImage from "../../Assets/Service/service-hero.png";

function ServiceDetails() {
  return (
    <div>
      <div className="w-full h-40 ps-8 pb-4 flex flex-col justify-end items-start bg-slate-200 lg:h-48 lg:ps-20 lg:pb-8">
        <div className="text-xl font-bold lg:text-4xl">Cleaning Service</div>
        <div className="mt-2 text-sm lg:text-base lg:mt-4">
          Home &gt; Services
        </div>
      </div>
      <div className="my-4 lg:my-8">
        <div className="w-11/12 mx-auto flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <img
              src={serviceImage}
              alt="service"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full py-4 text-start lg:w-1/2 lg:px-8">
            <div className="text-xl font-bold lg:text-2xl">
              Commercial Cleaning for a Pristine Workspace
            </div>
            <div className="mt-2 text-sm leading-relaxed lg:mt-4 lg:text-base lg:leading-loose">
              Our commercial cleaning services are tailored to elevate your
              business environment to new heights of cleanliness and
              professionalism. We understand that a pristine workspace fosters
              productivity, leaves a lasting impression on clients, and promotes
              a healthy atmosphere for employees. Our dedicated team of
              experienced cleaners specializes in transforming diverse
              commercial spaces, from offices and retail establishments to
              warehouses and medical facilities. With meticulous attention to
              detail and advanced cleaning techniques, we ensure that every nook
              and cranny is spotless. Let us take care of your cleaning needs so
              you can focus on what truly matters - growing your business.
            </div>
          </div>
        </div>
        <div className="w-11/12 mx-auto mt-2 text-start lg:mt-10">
          <div className="text-xl font-bold lg:text-2xl">
            How Can We Elevate Your Business? Discover Our Comprehensive
            Commercial Cleaning Services!
          </div>
          <div className="mt-2 text-sm ps-5 lg:mt-4 lg:text-base">
            <ul className="list-disc leading-relaxed lg:leading-loose lg:font-light">
              <li>
                Office Cleaning: Detailed cleaning of workspaces, cubicles, and
                communal areas to create a productive and inviting office
                environment.
              </li>
              <li>
                Retail Space Cleaning: Ensuring a spotless and welcoming
                shopping experience for customers by cleaning displays, floors,
                and restrooms.
              </li>
              <li>
                Medical Facility Cleaning: Thorough disinfection and cleaning of
                medical offices, waiting rooms, and treatment areas to maintain
                a hygienic atmosphere.
              </li>
              <li>
                Warehouse Cleaning: Removing dust, debris, and maintaining
                cleanliness in warehouse spaces to optimize safety and
                efficiency.
              </li>
              <li>
                Restaurant and Food Service Area Cleaning: Cleaning and
                sanitizing dining areas, kitchens, and food preparation spaces
                to meet health and safety standards.
              </li>
              <li>
                Educational Facility Cleaning: Creating a clean and healthy
                learning environment by disinfecting classrooms, restrooms, and
                common areas.
              </li>
              <li>
                Event Venue Cleaning: Preparing event spaces to shine before and
                after gatherings, ensuring a positive experience for attendees.
              </li>
              <li>
                Floor Care: Specialized floor cleaning, including carpet
                cleaning, tile and grout cleaning, and floor waxing to enhance
                aesthetics and durability.
              </li>
              <li>
                Window and Glass Cleaning: Ensuring clear and streak-free
                windows that enhance natural light and provide a polished
                appearance.
              </li>
              <li>
                Restroom Sanitization: Thorough cleaning and sanitization of
                restrooms to maintain a fresh and hygienic environment for
                employees and visitors.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDetails;
