import React, { useEffect, useState } from "react";
import CardCleaningService from "./CardCleaningService";
import cleaningServiceData from "../../Data/CleaningService.json";

const CleaningServices = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(1);

  // Function to update the number of cards to show based on screen width
  const updateCardsToShow = () => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setCardsToShow(4); // Show 4 cards for large screens
    } else if (width >= 768) {
      setCardsToShow(3); // Show 3 cards for medium screens
    } else {
      setCardsToShow(1); // Show 1 card for small screens
    }
  };
  useEffect(() => {
    updateCardsToShow(); // Set initial value based on the screen size
    window.addEventListener("resize", updateCardsToShow); // Listen for window resize
    return () => {
      window.removeEventListener("resize", updateCardsToShow); // Cleanup listener
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + cardsToShow >= cleaningServiceData.length
          ? 0
          : prevIndex + cardsToShow
      );
    }, 6000);
    return () => clearInterval(interval);
  }, [cardsToShow, currentIndex]);

  // Function to go to a specific slide (showing 3 cards)
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  // Get the current set of cards to display
  const getVisibleCards = () => {
    return cleaningServiceData.slice(currentIndex, currentIndex + cardsToShow);
  };

  return (
    <div className="pt-4 pb-6 relative flex flex-col items-center lg:py-12">
      {/* section: heading */}
      <div className="w-full px-8 text-start lg:text-center">
        <div className="text-xl font-bold lg:text-4xl">Cleaning Services</div>
        <div className="mt-2 text-sm font-light lg:mt-4 lg:text-base">
          Comprehensive cleaning solutions for every space: From residential
          homes to commercial
          <br /> offices, we have you covered!
        </div>
      </div>

      {/* section: carousel */}
      <div className="w-full pt-8 pb-4 flex justify-center space-x-[40px] overflow-x-hidden lg:py-8">
        {getVisibleCards().map((card, index) => (
          <CardCleaningService
            key={index}
            heading={card.heading}
            description={card.description}
            image={card.image}
          />
        ))}
      </div>

      {/* Dot navigation */}
      <div className="flex justify-center space-x-2">
        {Array.from({
          length: Math.ceil(cleaningServiceData.length / cardsToShow),
        }).map((_, index) => (
          <span
            key={index}
            className={`h-3 w-3 rounded-full cursor-pointer ${
              index === Math.floor(currentIndex / cardsToShow)
                ? "bg-black"
                : "bg-gray-400"
            }`}
            onClick={() => goToSlide(index * cardsToShow)}></span>
        ))}
      </div>
    </div>
  );
};
export default CleaningServices;
