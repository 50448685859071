import aboutImage from "../../Assets/About/about-hero.png";

function AboutUs() {
  return (
    <div>
      <div className="w-full h-40 ps-8 pb-4 flex flex-col justify-end items-start bg-slate-200 lg:h-48 lg:ps-20 lg:pb-8">
        <div className="text-xl font-bold lg:text-4xl">About Us</div>
        <div className="mt-2 text-sm lg:text-base lg:mt-4">Home &gt; About</div>
      </div>
      <div className="w-11/12 mx-auto mt-8">
        <div className="w-full">
          <img src={aboutImage} alt="about" className="w-full" />
        </div>
        <div className="mt-2 text-sm text-start leading-relaxed font-light lg:mt-4 lg:text-base">
          We believe that a clean environment is the foundation for a thriving
          and harmonious space. Established with a vision to redefine the
          cleaning industry, we are committed to delivering unmatched cleaning
          services that elevate both commercial and residential spaces.
        </div>
      </div>
      <div className="w-11/12 mx-auto mt-8 text-start">
        <div>
          <div className="text-xl font-bold lg:text-2xl">Our Story</div>
          <div className="mt-4 text-sm font-light leading-loose lg:text-base">
            Founded by cleaning industry veterans, John Rowles and Sarah James,
            our company was born out of a shared passion for cleanliness,
            professionalism, and a deep understanding of the impact a clean
            space has on individuals and businesses. With a combined experience
            of over 20 years in the industry, we've witnessed firsthand how a
            meticulously maintained environment can transform lives and enhance
            well-being.
          </div>
        </div>
        <div className="mt-8">
          <div className="text-xl font-bold lg:text-2xl">Our Philosophy</div>
          <div className="mt-4 text-sm font-light leading-loose lg:text-base">
            Our philosophy is simple yet powerful: cleanliness is more than just
            a service; it's a way of enhancing quality of life. We believe that
            a clean space fosters productivity, boosts morale, and creates a
            sense of pride. Whether it's a corporate office, a bustling retail
            store, a cozy home, or any other space, our mission is to infuse it
            with a new level of freshness and order.
          </div>
        </div>
        <div className="my-8">
          <div className="text-xl font-bold lg:text-2xl">What Sets Us Apart</div>
          <div className="mt-4 text-sm font-light lg:text-base">
            <ul className="list-disc ps-5 leading-loose">
              <li>
                Exceptional Service: Our dedicated team is committed to
                excellence. With rigorous training and a keen eye for detail, we
                go above and beyond to exceed your expectations.
              </li>
              <li>
                Customized Solutions: We understand that every space is unique.
                That's why we offer tailored cleaning solutions that address
                your specific needs, ensuring a perfect fit every time.
              </li>
              <li>
                Eco-Friendly Approach: We're not just passionate about
                cleanliness; we're also dedicated to preserving the environment.
                We use eco-conscious cleaning products and methods to ensure a
                sustainable impact.
              </li>
              <li>
                Trust and Reliability: We value your trust, which is why all our
                professionals undergo background checks. You can rely on us for
                safe, efficient, and secure cleaning services.
              </li>
              <li>
                Innovation and Expertise: We stay ahead of industry trends,
                constantly evolving our methods and embracing cutting-edge
                technologies to deliver the best possible results.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
