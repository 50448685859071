import FormBookService from "../FormBookService/FormBookService";
import backgroundWave from "../../Assets/Home/bg-wave.png";

const BookService = () => {
  return (
    <div className="w-full relative pb-12 bg-gray-100">
      <div className="w-full py-8 flex flex-col lg:flex-row lg:py-16">
        <div className="w-full order-2 lg:w-1/2 lg:order-1">
          <FormBookService />
        </div>
        <div className="w-full pb-4 flex flex-col order-1 lg:w-1/2 lg:order-2">
          <div className="px-8 text-start">
            <div className="text-xl font-bold lg:text-4xl">
              Send us your requests
            </div>
            <div className="mt-2 text-base font-medium lg:text-xl lg:mt-4">
              We excel in providing top-tier commercial cleaning services as
              industry leaders
            </div>
            <div className="mt-4 text-sm font-light leading-relaxed lg:mt-8 lg:text-base">
              At Absolute Shines Cleaning, we pride ourselves on offering
              tailored cleaning solutions that suit your specific needs. From
              routine maintenance to detailed deep cleaning, our team delivers
              exceptional results every time. Simply provide your details below,
              and let us transform your space into a spotless, welcoming
              environment. Your satisfaction is our priority—experience the
              shine today!
            </div>
          </div>
        </div>
      </div>
      <div className="w-full absolute bottom-0">
        <img src={backgroundWave} alt="wave" className="w-full" />
      </div>
    </div>
  );
};
export default BookService;
