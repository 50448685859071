import Navbar from "../../Components/Navbar/Navbar";
import ServiceDetails from "../../Components/ServiceDetails/ServiceDetails";
import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";

const Services = () => {
  return (
    <div>
      <Navbar />
      <ServiceDetails />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Services;
