import React, { useEffect, useState } from "react";
import CardCustomerReview from "./CardCustomerReview";
import customerLogo from "../../Assets/CustomerReview/client-1.png";
import customerReviewData from "../../Data/CustomerReview.json";
const CustomerReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(1);

  // Function to update the number of cards to show based on screen width
  const updateCardsToShow = () => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setCardsToShow(4); // Show 4 cards for large screens
    } else if (width >= 768) {
      setCardsToShow(3); // Show 3 cards for medium screens
    } else {
      setCardsToShow(1); // Show 1 card for small screens
    }
  };
  useEffect(() => {
    updateCardsToShow(); // Set initial value based on the screen size
    window.addEventListener("resize", updateCardsToShow); // Listen for window resize
    return () => {
      window.removeEventListener("resize", updateCardsToShow); // Cleanup listener
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + cardsToShow >= customerReviewData.length
          ? 0
          : prevIndex + cardsToShow,
      );
    }, 6000);
    return () => clearInterval(interval);
  }, [cardsToShow, currentIndex]);

  // Function to go to a specific slide (showing 3 cards)
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  // Get the current set of cards to display
  const getVisibleCards = () => {
    return customerReviewData.slice(currentIndex, currentIndex + cardsToShow);
  };

  return (
    <div className="py-4 lg:py-12">
      {/* section: heading */}
      <div className="mx-8 pt-5 text-xl font-bold lg:text-4xl">
        Words from Our Satisfied Customers
      </div>
      {/* section: card */}
      <div className="w-full pt-6 flex justify-center overflow-x-hidden space-x-4 lg:pt-10 pb-8">
        {getVisibleCards().map((review) => (
          <CardCustomerReview
            heading={review.heading}
            description={review.description}
            image={customerLogo}
            starCount={review.starCount}
          />
        ))}
      </div>
      {/* section: dot navigation */}
      <div className="flex justify-center space-x-2">
        {Array.from({
          length: Math.ceil(customerReviewData.length / cardsToShow),
        }).map((_, index) => (
          <span
            key={index}
            className={`h-2 w-2 lg:h-3 lg:w-3 rounded-full cursor-pointer ${
              index === Math.floor(currentIndex / cardsToShow)
                ? "bg-black"
                : "bg-gray-400"
            }`}
            onClick={() => goToSlide(index * cardsToShow)}
          ></span>
        ))}
      </div>
    </div>
  );
};
export default CustomerReviews;
