import emailjs from "@emailjs/browser";

interface sendEmailProps {
  subject: string;
  fullAddress: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  phone: string;
  email: string;
  message: string;
}

const sendEmail = (formData: sendEmailProps) => {
  return emailjs.send(
    "service_oli0u9h", // Service ID
    "template_ljoa7xh", // Template ID
    { ...formData }, // Email template data
    "3U5S3GAn0jyjUBjXt" // Public Key
  );
};

export default sendEmail;
