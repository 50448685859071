import React from "react";

const PageOne = ({ formData, handleChange }: any) => {
  return (
    <div className="space-y-4 mb-2 text-sm lg:text-base">
      {/* Subject */}
      <div>
        <input
          placeholder="Subject"
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>

      {/* Full Address */}
      <div>
        <input
          placeholder="Full Address"
          type="text"
          name="fullAddress"
          value={formData.fullAddress}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-between">
        {/* First Name */}
        <div>
          <input
            placeholder="First Name *"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
        {/* Last Name */}
        <div className="ms-2">
          <input
            placeholder="Last Name "
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
      </div>

      {/* Postal Code */}
      <div>
        <input
          placeholder="Postal Code"
          type="text"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>

      {/* Phone */}
      <div>
        <input
          placeholder="Phone *"
          type={"number"}
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>
      {/* Email */}
      <div>
        <input
          placeholder="Email*"
          type={"email"}
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>
      {/* Message */}
      <div>
        <textarea
          placeholder="Job Description, Special Requests, etc."
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          rows={3}
        ></textarea>
      </div>

      {/* Submit Button */}
    </div>
  );
};

export default PageOne;
