import star from "../../Assets/General/star.png";

interface CardCustomerReviewProps {
  heading: string;
  description: string;
  image: string;
  starCount: number;
}

const CardCustomerReview = ({
  heading,
  description,
  image,
  starCount,
}: CardCustomerReviewProps) => {
  const GetStars = (starCount: number) => {
    let stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(<img src={star} alt="test" className="h-5 w-5" />);
    }
    return stars;
  };

  return (
    <div className="border w-11/12 lg:w-80 h-auto flex flex-col justify-between rounded-md shadow-lg transition-transform transform ease-in-out duration-300 cursor-pointer hover:-translate-y-[3px] hover:shadow-lg">
      <div className="h-44 flex flex-col items-center justify-center">
        <img src={image} alt="review" />
        <div className="text-xl">{heading}</div>
        <div className="mt-2 flex justify-center items-center">
          <div className="flex">{GetStars(starCount)}</div>
        </div>
      </div>
      <div className="h-auto px-8 py-2 text-base font-light bg-slate-100">
        {description}
      </div>
    </div>
  );
};

export default CardCustomerReview;
