import Navbar from "../../Components/Navbar/Navbar";
import CleaningServices from "../../Components/CleaningServices/CleaningServices";
import ReasonsToChoose from "../../Components/ReasonsToChoose/ReasonsToChoose";
import CustomerReviews from "../../Components/CustomerReviews/CustomerReviews";
import BookService from "../../Components/BookService/BookService";
import ServiceArea from "../../Components/ServiceArea/ServiceArea";
import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";
import HeroSection from "../../Components/HeroSection/HeroSection";

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <CleaningServices />
      <ReasonsToChoose />
      <CustomerReviews />
      <BookService />
      <ServiceArea />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Home;
