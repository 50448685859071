import React from "react";
import ServiceArea from "../ServiceArea/ServiceArea";
import BookService from "../BookService/BookService";
import WriteToUs from "../WriteToUs/WriteToUs";
const ContactUs = () => {
  return (
    <div>
      <div className="w-full h-40 ps-8 pb-4 flex flex-col justify-end items-start bg-slate-200 lg:h-48 lg:ps-20 lg:pb-8">
        <div className="text-xl font-bold lg:text-4xl">Contact Us</div>
        <div className="mt-2 text-sm lg:text-base lg:mt-4">
          Home &gt; Contact
        </div>
      </div>
      <ServiceArea />
      <WriteToUs />
    </div>
  );
};

export default ContactUs;
