import { useState } from "react";
import PageOne from "./pageOne";
import sendEmail from "../../Utils/Service/sendEmail/sendEmail";
import Loader from "../Loader/Loader";

const initialFormData = {
  subject: "",
  fullAddress: "",
  firstName: "",
  lastName: "",
  postalCode: "",
  phone: "",
  email: "",
  message: "",
};

const FormBookService = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    console.log(formData); // You can handle form submission here
    setLoading(true);
    //sendEmail(formData);
    sendEmail(formData)
      .then((success: any) => {
        if (success) {
          console.log("Email sent successfully!");
        } else {
          console.log("Failed to send email.");
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after email sending completes
        setFormData(initialFormData); // Reset form data
      });
  };

  return (
    <div className="w-11/12 mx-auto p-2 bg-white rounded-md lg:w-3/4 lg:p-5">
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          action="https://formsubmit.co/shanthu67890@email.com"
          method="POST"
        >
          <PageOne formData={formData} handleChange={handleChange} />
          {/* Submit Button */}
          <button
            type="submit"
            className="w-1/3 py-2 flex justify-center bg-black text-white rounded-md text-sm hover:bg-slate-800 lg:text-base"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default FormBookService;
