import styles from "./HeroSection.module.css";
import backgroundWave from "../../Assets/Home/bg-wave.png";
import { useNavigate } from "react-router-dom";
function HeroSection() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/services");
  };
  return (
    <div className="h-96 lg:h-screen relative">
      <div className={`size-full ${styles.backgroundHero}`}>
        <div className="size-full px-8 flex flex-col justify-center items-start text-white bg-slate-800/25 lg:ps-24">
          <div className="text-start lg:w-1/2">
            <h1 className="text-xl font-bold lg:text-4xl">
              Absolute Shine, Absolute Clean
            </h1>
            <div className="mt-2 text-sm font-light lg:mt-4 lg:text-base">
              At Absolute Shines Cleaning, we ensure your spaces are spotless
              and inviting. We provide solutions that make your business shine.
            </div>
          </div>
          <div className="mt-8 lg:mt-32">
            <button
              onClick={onClick}
              type="button"
              className="border px-4 py-2 rounded-md text-sm hover:bg-gray-800/50 hover:border-transparent active:bg-gray-800/50 active:border-transparent ease-in-out duration-300 lg:px-5 lg:py-3 lg:text-base">
              Get Started
            </button>
          </div>
        </div>
      </div>
      {/* <HeroSection /> */}
      <div className="w-full absolute bottom-0 z-10">
        <img
          src={backgroundWave}
          alt="background wave"
          className="h-full w-full"
        />
      </div>
    </div>
  );
}

export default HeroSection;
